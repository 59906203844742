import axios from 'axios'
import store from './store'

const api = axios.create({
  baseURL: `${process.env.VUE_APP_ROOT_API}/marketing-packs`
})

api.interceptors.request.use((config) => {
  if (localStorage.getItem('accessToken')) {
    const headers = config.headers
    headers.Authorization = 'Bearer ' + localStorage.getItem('accessToken')
  }

  return config
}, (error) => {
  return Promise.reject(error)
})

api.interceptors.response.use((response) => {
  return response
}, (error) => {
  if (!error.response) {
    return Promise.reject(error)
  }

  const status = error.response.status
  if (status === 401) {
    return store.dispatch('logout')
  }

  if (status >= 400 && status <= 599 && status !== 422) {
    store.commit('setErrorCode', status)
  }

  return Promise.reject(error)
})

export { api as default }
