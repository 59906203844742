import api from '@/http'
import axios from 'axios'
import crypto from 'crypto-js'
import { Str } from 'laravel-js-str'

export default {
  async requestAuthorization (context, params = {}) {
    // await revokeToken()

    localStorage.state = Str.random(40)
    localStorage.codeVerifier = Str.random(128)
    localStorage.intended = window.location.pathname !== '/oauth/login' ? `${window.location.pathname}${window.location.search}` : window.location.search

    const host = process.env.VUE_APP_ROOT_API

    const query = new URLSearchParams({
      client_id: process.env.VUE_APP_CLIENT_ID,
      redirect_uri: `${window.location.protocol}//${window.location.host}/oauth/login`,
      response_type: 'code',
      scope: '*',
      state: localStorage.state,
      code_challenge: crypto.SHA256(localStorage.codeVerifier).toString(crypto.enc.Base64).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, ''),
      code_challenge_method: 'S256'
    })

    const query2 = new URLSearchParams({
      r: `/oauth/authorize?${query}`,
      force: params.force === undefined ? true : params.force,
      direct: params.direct === undefined ? false : params.direct
    })

    window.location.href = `${host}/login?${query2}`
  },

  async getToken (context, { state, code }) {
    const localState = localStorage.state
    const codeVerifier = localStorage.codeVerifier
    localStorage.removeItem('state')
    localStorage.removeItem('codeVerifier')
    if (localState !== state) {
      return Promise.reject(new Error('Unexpected state'))
    }

    const host = process.env.VUE_APP_ROOT_API

    const response = await axios.post(`${host}/oauth/token`, {
      grant_type: 'authorization_code',
      client_id: process.env.VUE_APP_CLIENT_ID,
      redirect_uri: `${window.location.protocol}//${window.location.host}/oauth/login`,
      code_verifier: codeVerifier,
      code: code
    })

    localStorage.accessToken = response.data.access_token
    localStorage.refreshToken = response.data.refresh_token

    return Promise.resolve(response)
  },

  logout (context) {
    localStorage.clear()
    context.dispatch('requestAuthorization')
  },

  fetchAuthUser (context) {
    return new Promise((resolve, reject) => {
      if (!localStorage.getItem('accessToken')) {
        context.commit('setAuthUser', null)
        resolve(null)
        return
      }

      if (context.state.authUser) {
        resolve(context.state.authUser)
        return
      }

      api.get('/auth-user').then((response) => {
        if (!response || response.data.type != 7) {
          localStorage.clear()
          resolve(response.data)
          return
        }
        context.commit('setAuthUser', response.data)
        resolve(response.data)
      }, (error) => {
        reject(error)
      })
    })
  },
}
