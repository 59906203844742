import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

const LoginPage = () => import('./pages/interviewer/auth/LoginPage')
const JobListPage = () => import('./pages/interviewer/JobListPage')
const JobPage = () => import('./pages/interviewer/JobPage')
const AccountPage = () => import('./pages/interviewer/AccountPage')
const AvailabilityPage = () => import('./pages/interviewer/AvailabilityPage')
const VendorLayout = () => import('./layouts/VendorLayout.vue')
const InterviewerLayout = () => import('./layouts/InterviewerLayout.vue')
const VendorPaymentPage = () => import('./pages/vendor/PaymentPage.vue')
const VendorApprovalPage = () => import('./pages/vendor/VendorApprovalPage.vue')
const ErrorPage = () => import('./pages/ErrorPage.vue')

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
      {
        path: '/vendor/orders/:orderId',
        component: VendorLayout,
        children: [
          {
            path: 'payment/:paymentId?',
            component: VendorPaymentPage,
          },
          {
            path: '',
            component: VendorApprovalPage
          },
        ]
      },
      {
        path: '/',
        component: InterviewerLayout,
        children: [
          {
            path: 'oauth/login',
            components: {
              auth: LoginPage
            }
          },
          {
            path: '/account',
            component: AccountPage,
            meta: {
              requiresAuthentication: true
            }
          },
          {
            path: '/availability',
            component: AvailabilityPage,
            meta: {
              requiresAuthentication: true
            }
          },
          {
            path: '/job/:orderId',
            component: JobPage,
            meta: {
              requiresAuthentication: true
            }
          },
          {
            path: '',
            component: JobListPage,
            meta: {
              requiresAuthentication: true
            }
          }
        ]
      },
      {
        path: '*',
        component: ErrorPage
      }
    ]
});


router.beforeEach((to, from, next) => {
  const requiresAuthentication = to.meta.requiresAuthentication

  if (requiresAuthentication && !localStorage.getItem('accessToken')) {
    store.dispatch('requestAuthorization')
    return
  }

  if (to.path !== from.path) {
    store.commit('setErrorMessage', null)
    store.commit('setSuccessMessage', null)
    store.commit('setErrorCode', null)
  }

  next()
})

export default router