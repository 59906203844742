import Vue from 'vue'
import moment from 'moment'

// Usage: {{ '2019-09-23 09:30:00' | date }}
// Output: 23/09/2019
Vue.filter('date', function (date) {
  return moment(date).format('DD/MM/YYYY')
})

// Usage: {{ '2019-09-23 09:30:00' | time }}
// Output: 09:30
Vue.filter('time', function (date) {
  return moment(date).format('HH:mm')
})

// Usage: {{ '2019-09-23 09:30:00' | datetime }}
// Output: 23/09/2019 09:30
Vue.filter('datetime', function (date) {
  return moment(date).format('DD/MM/YYYY HH:mm')
})

// Usage: {{ 9999 | number }}
// Output: 9,999
Vue.filter('number', function (number) {
  if (isNaN(number)) {
    return number
  }

  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
})

// Usage: {{ 9999 | number }}
// Output: £9,999
Vue.filter('gbp', function (value) {
  return '£' + Vue.filter('number')(Math.round(value))
})

// Usage: {{ '2022-07-04 09:30:00' | day }}
// Output: Monday
Vue.filter('day', function (date) {
  return moment(date).format('dddd')
})

// Usage: {{ '2022-07-04 09:30:00' | day }}
// Output: Mon
Vue.filter('short_day', function (date) {
  return moment(date).format('ddd')
})